import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { AuthComponents, State } from '@/store/models';
import Loader from '@/components/common/loader/Loader.vue';
import Login from '@/components/common/auth/login/Login.vue';
import AuthVerification from '@/components/common/auth/verification/AuthVerification.vue';
import Reset from '@/components/common/auth/reset/Reset.vue';
import Register from '@/components/common/auth/register/Register.vue';

@Component({
  components: {
    Loader,
  },
})

export default class AuthModal extends Vue {
  @StateClass auth!: State['auth'];
  @StateClass authModal!: State['authModal'];
  @Action openModal!: Function;
  @Getter('authPageTitle') title!: string;

  @Prop({ default: '/landing' })
  logoLink!: string;

  @Prop({ default: 'snake-case-id' })
  identifier!: string;

  @Prop({ default: null })
  initWithType!: AuthComponents;

  mounted(): void {
    this.$modal.show('authModal');
  }

  get loading(): boolean {
    return !!this.auth && this.auth.status === 'processing';
  }

  get currentComponentProps(): any {
    return { isAuthPage: false };
  }

  get currentComponent(): any {
    switch (this.authModal?.type) {
      case AuthComponents.Login:
        return Login;
      case AuthComponents.AuthVerification:
        return AuthVerification;
      case AuthComponents.Reset:
        return Reset;
      case AuthComponents.Register:
        return Register;
      default:
        return null;
    }
  }
}
